'use client';

import { useState, createContext, useCallback } from 'react';

//* Context
import withLanguageContext from '../consumerHOC/LanguageConsumer';

//* Helpers
import { api } from '@/helpers';

const DataContext = createContext(null);
export const DataConsumer = DataContext.Consumer;

const DataProvider = (props) => {
    const [state, setState] = useState({
        ...props.newData,
        prevRoute: null,
    });

    const getGlobalData = useCallback(() => {
        return state.globalData[props.selectedLang];
    }, [state, props.selectedLang]);

    const enableLoading = () => {
        this.setState({ loading: true });
    };

    const disableLoading = () => {
        setState((prev) => ({ ...prev, loading: false }));
    };

    const subscribe = async (data) => {
        return await api.post('subscribe', data);
    };

    const planSubscribe = async (data) => {
        return await api.post('plan-subscribe', data);
    };

    const downloadSubscribe = async (data) => {
        return await api.post('download-subscribe', data)
    }

    const contactUs = async (data) => {
        return await api.post('contact-us', data);
    };

    const sendQuiz = async (data) => {
        return await api.post('send-quiz', data)
    }

    const methods = {
        getGlobalData,
        enableLoading,
        disableLoading,
        subscribe,
        planSubscribe,
        contactUs,
        downloadSubscribe,
        sendQuiz
    };

    return <DataContext.Provider value={{ ...state, ...methods }}>{props.children}</DataContext.Provider>;
};

export default withLanguageContext(DataProvider, ['selectedLang']);
