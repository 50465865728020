export const POSTS = [
  {
    id: 1,
    title: "A timeless classic in a new way",
    text: `81.6% of capital owners do not share details about assets with their family members. They view early disclosure as risky, while understanding that late disclosure is simply impossible. It is based on 'Penguin Analytics, research of 13 500 respondents from 18 countries, with Net Worth from $3 million to $99 million.

In the event of an emergency, conveying all necessary details to family members can be challenging or impossible. Many believe that existing methods are either unreliable or cumbersome. However, the solution can be simple: grab a sheet of paper and a black marker.

Write down a list of your assets, capital, and their locations. Make several copies of this document. Use a black marker to obscure different parts of the information on each copy (this can also be done digitally in MS Word).

To ensure the information cannot be read through the redactions, photocopy these documents again. Distribute the lists to different family members.

Now, your family has crucial information that they can use in a critical moment, but they will need to come together to combine the pieces of the puzzle.

Risks: this method is not perfect, but is certainly better than having no plan at all. It works best if the family members are in reasonably good relationships with each other.`,
    metaDescription: `In the event of an emergency, conveying all necessary details to family members can be challenging or impossible. Many believe that existing methods are unreliable.`,
  },
  {
    id: 2,
    title: "Playing by one side’s rules",
    text: `42.8% of wealth owners are not aware of KYC risks and issues related to banks’ KYC (Know Your Client) procedures. It is based on 'Penguin Analytics, research of 13 500 respondents from 18 countries, with Net Worth from $3 million to $99 million.

Don’t get complacent, if you pass bank compliance when opening an account. Banks regularly conduct reviews of their clients and send requests for additional documentation. They may ask for statements or recommendation letters.

Get ready for it in advance; otherwise, you may not have sufficient time to gather the necessary documents, leading to the refusal of transactions.

To stay ahead, request recommendation letters, annual, and semi-annual statements from all banks where you hold accounts every six months. Typically, bank inquiries cover the current and previous year, and recommendation letters are valid for six to twelve months.

To save time, create a template that can be sent to all relevant banks. Most banks accept free-form requests, but some may require you to use their specific templates. In such cases, you’ll need to send separate requests.`,
    metaDescription: `Don’t get complacent, if you pass bank compliance when opening an account. Banks regularly conduct reviews of clients and send requests for documentation.
    `,
  },
  {
    id: 3,
    title: "Rhino running",
    text: `A rhinoceros runs fast and sees poorly, but its weight makes it a problem for anything in its path. Banks and partners don’t listen to you; they evaluate a person based on their digital profile. Services such as World-Check or Lexis Nexis are the sources of this data. These platforms were created to check counterparties for involvement in illegal financial activities.

However, in reality, they contain information on millions of people. These services are often associated with scandals and data leaks, unjust categorization of individuals as suspicious, and the inclusion of data from irrelevant sources, yet they continue to be widely used.

Do not leave this issue unattended. Request information about yourself regularly, at least every six months.

You don't need to have done something wrong; it's enough for the World-Check algorithm to flag you as suspicious, or for someone in your contacts to be linked to questionable transactions. There's also the risk of database errors or incorrect interpretations.

World-Check is a black box that significantly impacts your capital and assets, potentially leading to loan refusals, blocked transactions, denied bank accounts, and even revocation of residence permits or citizenship. Bank compliance departments work solely with documents and digital traces, so if you face unexplained refusals or biased treatment, World-Check could be the reason.`,
    metaDescription: `Banks don’t listen to you; they evaluate a person based on digital profile.`,
  },
  {
    id: 4,
    title: "The rearview mirror or the history of your money",
    text: `92% of capital founders underestimate the importance of Source of Wealth Essay (SoWE) with a proof of Continuity of Ownership. They consider it to be a document of little importance. It is based on 'Penguin Analytics, research of 13 500 respondents from 18 countries, with Net Worth from $3 million to $99 million.
  
Recently, banking compliance procedures have become increasingly stringent, and it is likely that they will only get worse in the future. Primarily, they request documents proving the continuity of ownership. (Continuity of ownership refers to the history of the origin of your wealth).

The timeframe for these requests has also changed: initially, regulators were interested in information from the past 6 months, then from the past year, and then from the past 3 years. These days, you may be required to provide data from the past 10 years.

If you have not yet recovered your data, each day you wait only worsens your situation, as your data becomes outdated while the depth of bank inquiries increases. If you do not start addressing this now, eventually these two trends will intersect, and you will find yourself in an untenable situation.

Start by recovering copies of documents from the past three years and then delve deeper. Recovering some documents may be difficult or impossible, but by starting this process now, you will already be ahead of many.
* Continuity of ownership - the history of all your assets`,
    metaDescription: `
Recently, banking compliance procedures have become increasingly stringent, and it is likely that they will only get worse in the future.`,
  },
  {
    id: 5,
    title: "Red button",
    text: `93% of wealth owners admit that they have no understanding of how their family will act in the event of force majeure. It is based on 'Penguin Analytics, research of 13 500 respondents from 18 countries, with Net Worth from $3 million to $99 million.

In the subway, on trains, and at workplaces, there is always a red button for emergencies. In human life, the variations of unexpected events are much greater. Nevertheless, statistics show that 99.2% of wealthy families do not have a precise action plan for emergencies.

Have you thought about what you will do and what plan your family will follow if such an event occurs? To avoid being part of this majority statistic, prepare in advance by developing several future scenarios. Even a simple list of basic actions and assets will prepare you and your family for the most unexpected events.

Remember to update the plan every six months. In the event of your sudden absence, the survival of your entire family and the preservation of assets will entirely depend on having clearly formulated and planned actions.`,
    metaDescription: `While subways and workplaces have red emergency buttons, 99.2% of wealthy families lack a clear action plan for life's unexpected events. Plan ahead.`,
  },
  {
    id: 6,
    title: "Ski slopes",
    text: `Only 4.02% realize that if their bank requests verification of a frozen transaction – they will have just three days to provide the documents. It is based on 'Penguin Analytics, research of 13 500 respondents from 18 countries, with Net Worth from $3 million to $99 million.

A black slope can be easier with good snow than a blue slope with bad snow. Often, the reasons behind account freezes or bank-canceled transactions are not immediately clear. Regulatory criteria for deeming client transactions suspicious are vague.

Sometimes, this judgment is made because a client has a large number of diverse transactions. This can lead to a transaction freeze and a request for additional documentation.

To avoid this, separate your bank accounts by transaction types, and ideally, conduct different types of transactions through different banks. This reduces the frequency of regular and unexpected requests and helps you manage and respond to bank inquiries.

For example, use separate accounts for dividends, current expenses, bonuses, and investment income. This approach saves time, is convenient, and reduces compliance risks.

Of course, dividing assets among multiple accounts and banks may increase the complexity of financial planning. Additionally, if you mistakenly mix up the accounts and conduct an atypical transaction, the regulator will likely send you a request.`,
    metaDescription: `Often, the reasons behind account freezes or bank-canceled transactions are not immediately clear. Regulatory criteria for deeming client transactions suspicious are vague.`,
  },
  {
    id: 7,
    title: "Follow the trail",
    text: `Private transactions from $134 000 to $4M, often scrutinized for KYC compliance, are mostly initiated by capital founders with assets between $3M and $99M. It is based on 'Penguin Analytics, research of 13 500 respondents from 18 countries, with Net Worth from $3 million to $99 million.

You’ve sent a payment to another country, and it’s taking a long time to reach its destination — this is a common scenario. People check the status of the payment with the sending bank, not realizing that there can be at least four intermediaries involved in the payment chain.

If any bank in this chain holds the payment longer than usual, it’s highly likely that you’ll be required to provide more detailed KYC (Know Your Customer) data next time, even if the payment goes through this time.

To be prepared and to track your transactions, you can use services to monitor SWIFT payments. These services track the status of your cross-border transactions in real time and notify you of any delays.

You can subscribe to paid versions or use free alternatives available online or through Telegram bots. If you don’t want to spend time on this, you can delegate the task to assistant, who can also monitor the transactions. However, keep in mind the risk of disclosing confidential information, as the data will show either the amount or the purpose of the transaction.

Additionally, a major drawback of these services is that they provide data only in real time and do not maintain statistics for individuals.`,
    metaDescription: `You’ve sent a payment to another country, and it’s taking a long time to reach its destination — this is a common scenario. People check the status of the payment with the sending bank, not realizing that there can be at least four intermediaries involved in the payment chain.`,
  },
  {
    id: 8,
    title: "The “Alien - Own” strategy: securing your family’s future",
    text: `92% of additionally surveyed lawyers claimed that in many cases having detailed information about assets is more important than having documents. It is based on 'Penguin Analytics, research of 13 500 respondents from 18 countries, with Net Worth from $3 million to $99 million.

In today’s world, our lives are diversified not only by types of assets, but also by the jurisdictions where these assets are located. Does ownership of an asset give you your own rights and how can we transfer this capital to heirs without causing them headaches? What rules will govern the inheritance of these assets?

The simplest solution might be to draft a will for each individual foreign asset located in its respective country. To create this document, you usually don’t need to travel; in most cases, it can be done at a consulate without the other party’s presence. The key is to plan how to transfer this information to your family in the event of an emergency.

Keep in mind that inheritance can be a lengthy and costly process, and until it is finalized, your heirs will have limited access to the assets. This poses a risk of improper management, potentially leading to asset loss. Additionally, inheritance may be subject to high taxes in the relevant country, adding to the financial burden.`,
    metaDescription: `In today’s world, our lives are diversified not only by types of assets, but also by the jurisdictions where these assets are located.What rules will govern the inheritance of these assets?`,
  },
  {
    id: 9,
    title: "SWIFT",
    text: `Few people closely track the movement of their international payments through SWIFT trackers (many of which are publicly available). However, it's worth doing, as the information obtained can signal potential problems ahead.

For example, when you send a USD payment from one country to another, it likely passes through a chain of banks, usually between 3 to 5. If the payment gets stuck at one of them, it’s likely that this bank has compliance or KYC-related questions for the sender.

In such a case, the bank sends a request to the previous bank in the chain, asking for the sender's client profile. They receive information about the sender’s identity and finances, conduct their own analysis, and then either process the payment or return it. In some cases, they may even freeze the payment.

If a gray-area or illegal payment passes through the banks, all the banks in the chain can be held responsible. This is why they are keen to check every payment—no one wants to be the last in the chain if the next bank blocks the payment. It is generally believed that if not for KYC checks, payments would go through almost instantly.

If such a request is made by the bank holding up the transaction, all banks in the chain will be aware of it. There's a high likelihood that your sending bank will take notice and start monitoring your future transactions more closely, or even initiate a more thorough review of your entire profile.

It's important to track payments and know where they are held up and for how long, even if they eventually go through successfully. This task can be delegated to an assistant, as some SWIFT trackers allow tracking by payment number (without revealing the amount or purpose), so you can maintain anonymity within your circle.

It’s crucial to have a well-prepared client file with each of your banks. But that’s a topic for another note.`,
    metaDescription: `Few people closely track the movement of their international payments through SWIFT trackers (many of which are publicly available).`,
  },
  {
    id: 10,
    title: "Cryptocurrency",
    text: `I have nothing against crypto. Quite the opposite. But everything must be done carefully. What’s the main issue with large-scale crypto transactions?

Statistics show that in 91% of cases, transitioning from traditional fiat money to crypto and then back again breaks the chain of continuous ownership. After that, the freedom to move capital significantly decreases. Why?

Let’s say you have a sum of money, for example, $1M in dividends from your business. You have all the necessary documentation, which makes you a respectable capital owner in the eyes of banks. Now, you decide to purchase cryptocurrency, such as Bitcoin or USDT.

The purchase of crypto is a standalone transaction. Later, when you exchange crypto back into traditional currency (whether tomorrow or in a year, but at some point, it will happen), that will be a separate, independent transaction. These two operations are not formally linked in any way.

As a result, you exchanged capital with a clean history for crypto, but when you exit, you lack supporting documentation. Your chain of continuous ownership is tragically broken. For small amounts, this isn’t a big deal, but for large amounts, it becomes a problem.

Any bank is highly cautious of such funds. When conducting a transaction, the receiving bank will also require a history of ownership, and the lack of it will be a red flag. In the end, you’ve turned an asset into capital that is difficult to move around the world.

Justifying the source of funds and maintaining ownership continuity is one of the mantras of the modern financial world. Banks want to understand every stage of a client’s сapital accumulation. Not abstractly, but in great detail, tracing the appearance and movement of every dollar.

The simple crypto transaction described above is one whose consequences are extremely difficult or impossible to fix. Can it be done differently? Yes, but statistics show that people only worry about this in 9% of cases.`,
    metaDescription: `I have nothing against crypto. Quite the opposite. But everything must be done carefully. What’s the main issue with large-scale crypto transactions?`,
  },
  {
    id: 11,
    title: "Vertical and Horizontal Family Capital",
    text: `There are many myths and illogical behaviors among wealthy families in our field. Many of these have been analyzed in Penguin Analytics.

One such myth goes like this: when money passes from hand to hand within a family, it's a vertical transfer (to children). Over 90% of our respondents and clients believe this to be true.

This is not the case at all. In most situations, the transfer is not vertical, but horizontal (to spouses). This occurs both in planned and unexpected transfers.

According to estimates from various analysts (Owner.One, UBS, and local banks), the volume of capital that will change hands within families over the next 10-20 years is projected to be between $75-95 trillion. Moreover, in the coming years alone, horizontal transfers (to spouses) will account for around $8-10 trillion of this capital and asset movement.

Projections indicate that this capital will remain at the horizontal level for another 10-15 years before it is transferred vertically (to children).
What are the conclusions?

In most cases, part of the capital is lost during the transfer from one hand to another (not afterward, as is commonly believed). As a result, the actual loss rate doubles.

The founder of the capital needs to pass on not just the capital itself to family members but also its detailed and formalized history since its inception.
The increasing compliance and KYC (Know Your Customer) requirements of financial institutions make it extremely difficult, even for the founder, to reconstruct the capital’s history. Without their involvement, it's almost impossible.

As a result, family members receive capital that they can use only until the first question arises about its origin.

Among capital founders, 42.86% do not know the details of preparing their capital and assets for KYC. Among their family members, a staggering 88.06% are completely unaware of these matters.

The problem is exacerbated by the fact that only 4.50% of family heads understand that by doing nothing now, they are merely postponing the issue and passing it onto their family members.

How to be prepared for this problem at any given moment is a topic for another post. For now, it’s important to simply recognize that this is a much more complex problem than the physical transfer of capital.`,
    metaDescription: `There are many myths and illogical behaviors among wealthy families in our field. Many of these have been analyzed in Penguin Analytics.`,
  },
  {
    id: 12,
    title: "Bitcoin, US Reserves, and Your Wallet",
    text: `Bitcoin (BTC) is slowly but surely carving out its place in the legal world. Fundamental changes that could affect all of us might be coming to the US.

Republicans, eyeing a potential Trump presidency (a crypto supporter), have prepared a rather progressive bill. If passed, Bitcoin would become an official reserve asset of the US. This would be just a step away from becoming a tool of monetary policy.

If BTC were already considered a reserve asset today, its share would be about 1.8% (with total reserves around $850 billion). Notably, most of this is confiscated from illegal and suspicious transactions. If the bill is passed, the US would purchase an additional 1 million BTC over 5 years, raising its share in reserves to about 8%.

So, what does this mean for us?

Many wealthy families keep a portion of their wealth in crypto assets. Currently, 4.94%* of wealthy families have such investments, and this number is growing, with increasing allocation of family capital to digital assets.

Regulation of crypto transparency has recently been escalating at an exponential rate. The FATF is rapidly and aggressively imposing strict requirements on the crypto market similar to traditional fiat transactions—compliance, KYC, KYT, KYB, AML, and other unwelcome terms.

The US is a marker for global financial markets. If (or when?) the bill becomes law, it will give a significant boost to crypto regulation, leading to a rapid end to crypto freedom and the full adoption of traditional financial transaction standards. These are quite strict rules.

Those investing in crypto now may face problems soon—problems such as reconstructing the chain of ownership, compliance with KYC requirements, and difficulties in converting crypto to fiat, as well as freedom of capital movement globally.

What should you do? It’s wise to start preparing and conducting crypto transactions as if fiat world rules are already fully applicable to crypto. Currently, this approach is used in only 9%* of cases.

(*) Statistics are from <a href="https://owner.one/analytics/">Penguin Analytics by Owner.One</a>`,
    metaDescription: `Bitcoin (BTC) is slowly but surely carving out its place in the legal world. Fundamental changes that could affect all of us might be coming to the US.`,
  },
];

export const ANNOUNCMENTS = [
  {
    title: "How to safeguard against misconduct by asset managers?",
    status: "awaiting_publication",
  },
  {
    title: "How should I approach discussing wealth with my family?",
    status: "awaiting_publication",
  },
  {
    title: "What strategies can be employed to create a positive impression with the Compliance Department?",
    status: "awaiting_answer",
  },
  {
    title: "How to streamline and organize the management and storage of financial information?",
    status: "awaiting_answer",
  },
  {
    title: "What are the risks of dealing with cryptocurrencies?",
    status: "awaiting_answer",
  },
];
