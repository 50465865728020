import { createGlobalStyle, css } from 'styled-components';

const Typography = createGlobalStyle`${css`
	* {
		box-sizing: border-box;
		outline: none;
		margin: 0;
		-webkit-appearance: none;
		-webkit-text-size-adjust: 100%;
		-webkit-font-smoothing: antialiased;
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important; //! Touch highlight color
	}
	html, body {
		overflow-x: clip;
		overflow: ${(props) => (props.popupIsOpen ? 'hidden;' : '')};
		overflow: ${(props) => (props.isBurgerMenuOpen ? 'hidden;' : '')};
	}
	body {
	    word-break: break-word;
		background-color: var(--backgroundColor);
		main {
			position: relative;
			display: flex;
			flex-direction: column;
			min-height: 100vh;
			z-index: 1;
			padding-top: var(--headerSpacing);
			transition: padding-top var(--trTime);
		}

		&.hidden {
			overflow: hidden;
			max-height: 100vh;
		}
	}
	//! Font sizes
	.h1,
	h1 {
		font-size: var(--h1);
		line-height: var(--lineHeightS);
	}

	.h2,
	h2 {
		font-size: var(--h2);
		line-height: var(--lineHeightS);
	}

	.h3,
	h3 {
		font-size: var(--h3);
		line-height: var(--lineHeightS);
	}

	.h4,
	h4 {
		font-size: var(--h4);
		line-height: var(--lineHeightS);
	}

	.h5,
	h5 {
		font-size: var(--h5);
		line-height: var(--lineHeightM);
	}

	.h6,
	h6 {
		font-size: var(--h6);
		line-height: var(--lineHeightM);
	}

	.p,
	p {
		font-size: var(--p);
		line-height: var(--lineHeightL);

		--p: var(--p1);

		&.p1 {
			--p: var(--p1);
			line-height: var(--lineHeightM);
		}

		&.p2 {
			--p: var(--p2);
		}

		&.p3 {
			--p: var(--p3);
		}

		&.p4 {
			--p: var(--p4);
		}

		&.p5 {
			--p: var(--p5);
		}

		&.p6 {
			--p: var(--p6);
		}

		&.p7 {
			--p: var(--p7);
		}
	}

	ul,
	fieldset {
		padding: 0;
		list-style: none;
		border: none;
	}

	button {
		padding: 0;
		border-width: 0;
		border-color: inherit;
		background-color: inherit;
	}

	textarea {
		resize: none;
		scrollbar-width: none; /* Firefox */
		-ms-overflow-style: none; /* IE and Edge */

		&::-webkit-scrollbar {
			display: none;
		}
	}

	a {
		color: unset;
		text-decoration: none;
		-webkit-tap-highlight-color: transparent;
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	p {
		margin-block-start: 0;
		margin-block-end: 0;
	}

	input[type='search']::-webkit-search-decoration,
	input[type='search']::-webkit-search-cancel-button,
	input[type='search']::-webkit-search-results-button,
	input[type='search']::-webkit-search-results-decoration {
		-webkit-appearance: none;
	}

	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
	}

	input[type='number'] {
		-moz-appearance: textfield;
	}

	input:focus::placeholder {
		color: transparent;
	}

	input {
		outline: none;
	}

	//! Change the transparent to any color
	textarea:-webkit-autofill,
	textarea:-webkit-autofill:hover,
	textarea:-webkit-autofill:focus,
	select:-webkit-autofill,
	select:-webkit-autofill:hover,
	select:-webkit-autofill:focus,
	input:-webkit-autofill,
	input:-webkit-autofill:hover,
	input:-webkit-autofill:focus,
	input:-internal-autofill-selected {
		color: var(--white1000);
		border-bottom: 1px solid var(--white1000);
		background-color: transparent;
		transition: background-color 5000s ease-in-out 0s;
		box-shadow: inset 1000px 1000px var(--royalBlue1000);
		-webkit-text-fill-color: var(--white1000);
	}

	@keyframes autofill {
		to {
			background-color: transparent;
		}
	}
`}`;

export default Typography;
