import { createGlobalStyle, css } from 'styled-components';

function columnGenerator(suffix) {
	let style = '';

	for (let i = 1; i <= 12; i++) {
		style += suffix ? `.col-${suffix}-${i}` : `.col-${i}`;
		style += `{ width: ${(100 / 12) * i}% }`;
	}

	return style;
}

const HelperClass = createGlobalStyle`${css`
	//! Fonts
	.font-argent {
		font-family: var(--argent);
		font-weight: 300;
	}

	.font-argent-light {
		font-family: var(--argent);
		font-weight: 500;
	}

	.font-argent-demi {
		font-family: var(--argent);
		font-weight: 700;
	}

	.font-avenir-regular {
		font-family: var(--avenir);
		font-weight: 400;
	}

	.font-avenir-medium {
		font-family: var(--avenir);
		font-weight: 500;
	}

	.font-avenir-demi {
		font-family: var(--avenir);
		font-weight: 600;
	}

	//! Colors
	.color-gold {
		color: var(--gold);

		&-bg {
			background-color: var(--gold);
		}
	}
	.gray-1000 {
		color: var(--gray1000);

		&-bg {
			background-color: var(--gray1000);
		}
	}
	.royal-blue-1000 {
		color: var(--royalBlue1000);

		&-bg {
			background-color: var(--royalBlue1000);
		}
	}

	.royal-blue-900 {
		color: var(--royalBlue900);

		&-bg {
			background-color: var(--royalBlue900);
		}
	}

	.royal-blue-800 {
		color: var(--royalBlue800);

		&-bg {
			background-color: var(--royalBlue800);
		}
	}

	.royal-blue-700 {
		color: var(--royalBlue700);

		&-bg {
			background-color: var(--royalBlue700);
		}
	}

	.royal-blue-600 {
		color: var(--royalBlue600);

		&-bg {
			background-color: var(--royalBlue600);
		}
	}

	.royal-blue-500 {
		color: var(--royalBlue500);

		&-bg {
			background-color: var(--royalBlue500);
		}
	}

	.royal-blue-400 {
		color: var(--royalBlue400);

		&-bg {
			background-color: var(--royalBlue400);
		}
	}

	.royal-blue-300 {
		color: var(--royalBlue300);

		&-bg {
			background-color: var(--royalBlue300);
		}
	}

	.royal-blue-200 {
		color: var(--royalBlue200);

		&-bg {
			background-color: var(--royalBlue200);
		}
	}

	.royal-blue-100 {
		color: var(--royalBlue100);

		&-bg {
			background-color: var(--royalBlue100);
		}
	}

	.royal-blue-50 {
		color: var(--royalBlue50);

		&-bg {
			background-color: var(--royalBlue50);
		}
	}

	//! Neutral colors
	.white-1000 {
		color: var(--white1000);

		&-bg {
			background-color: var(--white1000);
		}
	}

	.white-750 {
		color: var(--white750);

		&-bg {
			background-color: var(--white750);
		}
	}

	.white-500 {
		color: var(--white500);

		&-bg {
			background-color: var(--white500);
		}
	}

	.white-250 {
		color: var(--white250);

		&-bg {
			background-color: var(--white250);
		}
	}

	//! Secondary Colors
	.error-color {
		color: var(--errorColor);

		&-bg {
			background-color: var(--errorColor);
		}
	}

	.success-color {
		color: var(--successColor);

		&-bg {
			background-color: var(--successColor);
		}
	}

	.warning-color {
		color: var(--warningColor);

		&-bg {
			background-color: var(--warningColor);
		}
	}

	//! Background Color
	.background-color {
		color: var(--backgroundColor);

		&-bg {
			background-color: var(--backgroundColor);
		}
	}

	//! Letter Spacings
	.letter-spacing-h1 {
		letter-spacing: var(--letterSpacingH1);
	}

	.letter-spacing-h2 {
		letter-spacing: var(--letterSpacingH2);
	}

	.letter-spacing-h3 {
		letter-spacing: var(--letterSpacingH3);
	}

	.letter-spacing-h4 {
		letter-spacing: var(--letterSpacingH4);
	}

	.letter-spacing-h5 {
		letter-spacing: var(--letterSpacingH5);
	}

	.letter-spacing-h6 {
		letter-spacing: var(--letterSpacingH6);
	}

	.letter-spacing-p1 {
		letter-spacing: var(--letterSpacingP1);
	}

	.letter-spacing-p2 {
		letter-spacing: var(--letterSpacingP2);
	}

	.letter-spacing-p3 {
		letter-spacing: var(--letterSpacingP3);
	}

	.letter-spacing-p4 {
		letter-spacing: var(--letterSpacingP4);
	}

	.letter-spacing-p5 {
		letter-spacing: var(--letterSpacingP5);
	}

	.letter-spacing-p6 {
		letter-spacing: var(--letterSpacingP6);
	}

	.letter-spacing-p7 {
		letter-spacing: var(--letterSpacingP7);
	}

	//! For Text
	.uppercase {
		text-transform: uppercase;
	}

	.lowercase {
		text-transform: lowercase;
	}

	.capitalize {
		text-transform: capitalize;
	}

	.underline {
		text-decoration: underline;
	}

	.text-center {
		text-align: center;
	}

	.pre-wrap {
		white-space: pre-wrap;
	}

	//! Grid
	.row {
		display: flex;
		flex-wrap: wrap;
		margin: 0 calc(var(--colPadding) * -1);

		> * {
			width: 100%;
			padding: 0 var(--colPadding);
		}

		${columnGenerator()}

		@media only screen and (max-width: ${({ theme }) => theme.mediaQuery.desktopSizeXLMin}) {
			${columnGenerator('dxl')}
		}

		@media only screen and (max-width: ${({ theme }) => theme.mediaQuery.desktopSizeLMin}) {
			${columnGenerator('dl')}
		}

		@media only screen and (max-width: ${({ theme }) => theme.mediaQuery.desktopSizeMMin}) {
			${columnGenerator('dm')}
		}

		@media only screen and (max-width: ${({ theme }) => theme.mediaQuery.desktopSizeSMin}) {
			${columnGenerator('ds')}
		}

		@media only screen and (max-width: ${({ theme }) => theme.mediaQuery.tabletSizeMin}) {
			${columnGenerator('t')}
		}

		@media only screen and (max-width: ${({ theme }) => theme.mediaQuery.tabletSizeSMin}) {
			${columnGenerator('m')}
		}
	}
	#smooth-content {
		will-change: unset;
	}
	.ant-tooltip {
		box-sizing: border-box;
		margin: 0;
		padding: 0;
		color: rgba(0, 0, 0, 0.85);
		font-family: var(--avenir);
		font-size: 14px;
		line-height: 1.29;
		list-style: none;
		position: absolute;
		z-index: 1070;
		display: block;
		width: -moz-max-content;
		width: max-content;
		width: intrinsic;
		max-width: 250px;
		visibility: visible;
		&-content {
			position: relative;
		}
		&-hidden {
			display: none;
		}
		&-inner {
			min-width: 30px;
			min-height: 32px;
			padding: 16px;
			color: #5B5C70;
			text-align: left;
			text-decoration: none;
			word-wrap: break-word;
			background-color: var(--white1000);
			border-radius: 12px;
			box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
		  }
	  }


	  .ant-tooltip-placement-top,
	  .ant-tooltip-placement-topLeft,
	  .ant-tooltip-placement-topRight {
		padding-bottom: 14.3137085px;
	  }
	  .ant-tooltip-placement-right,
	  .ant-tooltip-placement-rightTop,
	  .ant-tooltip-placement-rightBottom {
		padding-left: 14.3137085px;
	  }
	  .ant-tooltip-placement-bottom,
	  .ant-tooltip-placement-bottomLeft,
	  .ant-tooltip-placement-bottomRight {
		padding-top: 14.3137085px;
	  }
	  .ant-tooltip-placement-left,
	  .ant-tooltip-placement-leftTop,
	  .ant-tooltip-placement-leftBottom {
		padding-right: 14.3137085px;
	  }

	  .ant-tooltip-arrow {
		position: absolute;
		z-index: 2;
		display: block;
		width: 22px;
		height: 22px;
		overflow: hidden;
		background: transparent;
		pointer-events: none;
	  }
	  .ant-tooltip-arrow-content {
		--antd-arrow-background-color: var(--white1000);
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		display: block;
		width: 11.3137085px;
		height: 11.3137085px;
		margin: auto;
		content: '';
		pointer-events: auto;
		border-radius: 0 0 2px;
		pointer-events: none;
	  }
	  .ant-tooltip-arrow-content::before {
		position: absolute;
		top: -11.3137085px;
		left: -11.3137085px;
		width: 33.9411255px;
		height: 33.9411255px;
		background: var(--antd-arrow-background-color);
		background-repeat: no-repeat;
		background-position: -10px -10px;
		content: '';
		-webkit-clip-path: inset(33% 33%);
				clip-path: inset(33% 33%);
		-webkit-clip-path: path('M 9.849242404917499 24.091883092036785 A 5 5 0 0 1 13.384776310850237 22.627416997969522 L 20.627416997969522 22.627416997969522 A 2 2 0 0 0 22.627416997969522 20.627416997969522 L 22.627416997969522 13.384776310850237 A 5 5 0 0 1 24.091883092036785 9.849242404917499 L 23.091883092036785 9.849242404917499 L 9.849242404917499 23.091883092036785 Z');
				clip-path: path('M 9.849242404917499 24.091883092036785 A 5 5 0 0 1 13.384776310850237 22.627416997969522 L 20.627416997969522 22.627416997969522 A 2 2 0 0 0 22.627416997969522 20.627416997969522 L 22.627416997969522 13.384776310850237 A 5 5 0 0 1 24.091883092036785 9.849242404917499 L 23.091883092036785 9.849242404917499 L 9.849242404917499 23.091883092036785 Z');
	  }
	  .ant-tooltip-placement-top .ant-tooltip-arrow,
	  .ant-tooltip-placement-topLeft .ant-tooltip-arrow,
	  .ant-tooltip-placement-topRight .ant-tooltip-arrow {
		bottom: 0;
		transform: translateY(100%);
	  }
	  .ant-tooltip-placement-top .ant-tooltip-arrow-content,
	  .ant-tooltip-placement-topLeft .ant-tooltip-arrow-content,
	  .ant-tooltip-placement-topRight .ant-tooltip-arrow-content {
		box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
		transform: translateY(-11px) rotate(45deg);
	  }
	  .ant-tooltip-placement-top .ant-tooltip-arrow {
		left: 50%;
		transform: translateY(100%) translateX(-50%);
	  }
	  .ant-tooltip-placement-topLeft .ant-tooltip-arrow {
		left: 13px;
	  }
	  .ant-tooltip-placement-topRight .ant-tooltip-arrow {
		right: 13px;
	  }
	  .ant-tooltip-placement-right .ant-tooltip-arrow,
	  .ant-tooltip-placement-rightTop .ant-tooltip-arrow,
	  .ant-tooltip-placement-rightBottom .ant-tooltip-arrow {
		left: 0;
		transform: translateX(-100%);
	  }
	  .ant-tooltip-placement-right .ant-tooltip-arrow-content,
	  .ant-tooltip-placement-rightTop .ant-tooltip-arrow-content,
	  .ant-tooltip-placement-rightBottom .ant-tooltip-arrow-content {
		box-shadow: -3px 3px 7px rgba(0, 0, 0, 0.07);
		transform: translateX(11px) rotate(135deg);
	  }
	  .ant-tooltip-placement-right .ant-tooltip-arrow {
		top: 50%;
		transform: translateX(-100%) translateY(-50%);
	  }
	  .ant-tooltip-placement-rightTop .ant-tooltip-arrow {
		top: 5px;
	  }
	  .ant-tooltip-placement-rightBottom .ant-tooltip-arrow {
		bottom: 5px;
	  }
	  .ant-tooltip-placement-left .ant-tooltip-arrow,
	  .ant-tooltip-placement-leftTop .ant-tooltip-arrow,
	  .ant-tooltip-placement-leftBottom .ant-tooltip-arrow {
		right: 0;
		transform: translateX(100%);
	  }
	  .ant-tooltip-placement-left .ant-tooltip-arrow-content,
	  .ant-tooltip-placement-leftTop .ant-tooltip-arrow-content,
	  .ant-tooltip-placement-leftBottom .ant-tooltip-arrow-content {
		box-shadow: 3px -3px 7px rgba(0, 0, 0, 0.07);
		transform: translateX(-11px) rotate(315deg);
	  }
	  .ant-tooltip-placement-left .ant-tooltip-arrow {
		top: 50%;
		transform: translateX(100%) translateY(-50%);
	  }
	  .ant-tooltip-placement-leftTop .ant-tooltip-arrow {
		top: 5px;
	  }
	  .ant-tooltip-placement-leftBottom .ant-tooltip-arrow {
		bottom: 5px;
	  }
	  .ant-tooltip-placement-bottom .ant-tooltip-arrow,
	  .ant-tooltip-placement-bottomLeft .ant-tooltip-arrow,
	  .ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
		top: 0;
		transform: translateY(-100%);
	  }
	  .ant-tooltip-placement-bottom .ant-tooltip-arrow-content,
	  .ant-tooltip-placement-bottomLeft .ant-tooltip-arrow-content,
	  .ant-tooltip-placement-bottomRight .ant-tooltip-arrow-content {
		box-shadow: -3px -3px 7px rgba(0, 0, 0, 0.07);
		transform: translateY(11px) rotate(225deg);
	  }
	  .ant-tooltip-placement-bottom .ant-tooltip-arrow {
		left: 50%;
		transform: translateY(-100%) translateX(-50%);
	  }
	  .ant-tooltip-placement-bottomLeft .ant-tooltip-arrow {
		left: 13px;
	  }
	  .ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
		right: 13px;
	  }
	  .ant-tooltip-pink .ant-tooltip-inner {
		background-color: #eb2f96;
	  }
	  .ant-tooltip-pink .ant-tooltip-arrow-content::before {
		background: #eb2f96;
	  }
	  .ant-tooltip-magenta .ant-tooltip-inner {
		background-color: #eb2f96;
	  }
	  .ant-tooltip-magenta .ant-tooltip-arrow-content::before {
		background: #eb2f96;
	  }
	  .ant-tooltip-red .ant-tooltip-inner {
		background-color: #f5222d;
	  }
	  .ant-tooltip-red .ant-tooltip-arrow-content::before {
		background: #f5222d;
	  }
	  .ant-tooltip-volcano .ant-tooltip-inner {
		background-color: #fa541c;
	  }
	  .ant-tooltip-volcano .ant-tooltip-arrow-content::before {
		background: #fa541c;
	  }
	  .ant-tooltip-orange .ant-tooltip-inner {
		background-color: #fa8c16;
	  }
	  .ant-tooltip-orange .ant-tooltip-arrow-content::before {
		background: #fa8c16;
	  }
	  .ant-tooltip-yellow .ant-tooltip-inner {
		background-color: #fadb14;
	  }
	  .ant-tooltip-yellow .ant-tooltip-arrow-content::before {
		background: #fadb14;
	  }
	  .ant-tooltip-gold .ant-tooltip-inner {
		background-color: #faad14;
	  }
	  .ant-tooltip-gold .ant-tooltip-arrow-content::before {
		background: #faad14;
	  }
	  .ant-tooltip-cyan .ant-tooltip-inner {
		background-color: #13c2c2;
	  }
	  .ant-tooltip-cyan .ant-tooltip-arrow-content::before {
		background: #13c2c2;
	  }
	  .ant-tooltip-lime .ant-tooltip-inner {
		background-color: #a0d911;
	  }
	  .ant-tooltip-lime .ant-tooltip-arrow-content::before {
		background: #a0d911;
	  }
	  .ant-tooltip-green .ant-tooltip-inner {
		background-color: #52c41a;
	  }
	  .ant-tooltip-green .ant-tooltip-arrow-content::before {
		background: #52c41a;
	  }
	  .ant-tooltip-blue .ant-tooltip-inner {
		background-color: #1890ff;
	  }
	  .ant-tooltip-blue .ant-tooltip-arrow-content::before {
		background: #1890ff;
	  }
	  .ant-tooltip-geekblue .ant-tooltip-inner {
		background-color: #2f54eb;
	  }
	  .ant-tooltip-geekblue .ant-tooltip-arrow-content::before {
		background: #2f54eb;
	  }
	  .ant-tooltip-purple .ant-tooltip-inner {
		background-color: #722ed1;
	  }
	  .ant-tooltip-purple .ant-tooltip-arrow-content::before {
		background: #722ed1;
	  }
	  .ant-tooltip-rtl {
		direction: rtl;
	  }
	  .ant-tooltip-rtl .ant-tooltip-inner {
		text-align: right;
	  }

	  .ant-message {
		box-sizing: border-box;
		margin: 0;
		padding: 0;
		color: var(--white1000);
		font-size: 14px;

		line-height: 1;
		list-style: none;

		position: fixed;
		bottom: 64px;
		left: 0;
		z-index: 1010;
		width: 100%;
		pointer-events: none;

		font-family: var(--avenir);

		&-notice {
			padding: 8px;
			text-align: center;
			&-content {
				display: inline-block;
				padding: 20px 28px;
				background: var(--royalBlue1000);
				border-radius: 12px;
				pointer-events: all;
			}
			&.ant-move-up-leave.ant-move-up-leave-active {
				animation-name: MessageMoveOut;
				animation-duration: 0.3s;
			}
		}
		.anticon {
			position: relative;
			top: 1px;
			margin-right: 8px;
			font-size: 16px;
		}
	  }
	  @keyframes MessageMoveOut {
		0% {
		  max-height: 150px;
		  padding: 8px;
		  opacity: 1;
		}
		100% {
		  max-height: 0;
		  padding: 0;
		  opacity: 0;
		}
	  }
`}`;

export default HelperClass;
