import { forwardRef } from 'react';
import styled from 'styled-components';

//* HOC's
import { withLanguageContext } from '@/context';

//* Helpers
import { stringToLetters } from '@/helpers';

//* Components
import Translate from '../Translate';

const Wrapper = styled.p``;

const Text = forwardRef((props, ref) => {
	let string = (
		<Translate
			val={props.text}
			vars={props.vars}
		/>
	);

	return (
		<Wrapper
			itemProp={props.itemProp}
			ref={ref}
			as={props.tag}
			data={props.data}
			style={props.style}
            className={`comp-text ${props.className}`}
			onClick={props.onClick || null}
			onMouseEnter={props.onMouseEnter || null}
			{...props.attr}>
			{props.tag == 'string' ? stringToLetters(props.translate(props.text)) : props.children || string}
		</Wrapper>
	);
});

Text.displayName = 'Text';

export default withLanguageContext(Text, ['translate']);
